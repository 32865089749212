<template lang="pug">
.Timer
  .containerTimer(style="--duration: 8; --size: 80;")
    //- TODO: In the future modified here duration
    .circle_mask
    .timer_container(v-if="showProgressBar")
      .timer(ref="timer")
        .mask
    .circle(ref="circle" @click="goHomeReal")
      home(size="40px")
</template>

<script>
import Home from "mdi-vue/Home.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "Timer",
  props: ["containerImageDocs", "showProgressBar", "timer", "goHomeReal"],
  computed: {
    ...mapState({
      documentsCropperState: state => state.autoservice.documentsCropper
    })
  },
  watch: {
    documentsCropperState(val) {
      if (val.length) {
        const timer = setTimeout(() => {
          this.containerImageDocs = document.querySelector(
            ".containerImageDocs"
          );

          this.containerImageDocs.onscroll = () => {
            this.showProgressBar = false;

            const timeout = setTimeout(() => {
              this.showProgressBar = true;
              this.startTimer();

              clearTimeout(timeout);
            }, 10);
          };
          clearTimeout(timer);
        }, 10);
      }
    }
  },
  components: {
    Home
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      setExplanation: "autoservice/setExplanation",
      onStopTimer: "autoservice/onStopTimer"
    })
  }
};
</script>

<style>
.timer {
  background: -webkit-linear-gradient(
    left,
    rgb(102, 149, 187) 50%,
    rgb(178, 210, 238) 50%
  );
  border-radius: 100%;
  height: calc(var(--size) * 1px);
  width: calc(var(--size) * 1px);
  position: relative;
  animation: time calc(var(--duration) * 1s) steps(1000, start) infinite;
  -webkit-mask: radial-gradient(#813737 50%, #000 50%);
  mask: radial-gradient(#813737 50%, #000 50%);
}

.circle_mask {
  height: calc((var(--size) - 20) * 1px);
  width: calc((var(--size) - 20) * 1px);
  position: absolute;
  border-radius: 50%;
  background: var(--color-primary);
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  box-shadow: 0 0 3px 10px rgb(178, 210, 238);
}

.timer_container {
  animation: onVisible 0.2s ease-in-out alternate 1 forwards;
}

.mask {
  border-radius: 100% 0 0 100% / 50% 0 0 50%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 50%;
  animation: mask calc(var(--duration) * 1s) steps(500, start) infinite;
  transform-origin: 100% 50%;
}

.Timer {
  height: 76px;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerTimer {
  position: relative;
  width: fit-content;
  height: 151px;
}

.circle {
  height: calc((var(--size) - 20) * 1px);
  width: calc((var(--size) - 20) * 1px);
  position: absolute;
  border-radius: 50%;
  background: var(--color-primary);
  z-index: 5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.circle span {
  margin-left: 11px;
  color: white;
}

.MenuSmall {
  font-size: 35px;
}

@keyframes time {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes mask {
  0% {
    background: rgb(178, 210, 238);
    transform: rotate(0deg);
  }
  50% {
    background: rgb(178, 210, 238);
    transform: rotate(-180deg);
  }
  50.01% {
    background: rgb(102, 149, 187);
    transform: rotate(0deg);
  }
  100% {
    background: rgb(102, 149, 187);
    transform: rotate(-180deg);
  }
}

@keyframes onVisible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
