<template lang="pug">
.col-12.header_dk
  .ContainerImage
    img(class="client_logo" :src="Logo" v-openlog)
</template>
<script>
import LogoColmedica from "@/assets/colmedica_logo.png";
import { mapState } from "vuex";

export default {
  name: "Header",

  data: () => ({
    LogoColmedica
  }),

  computed: {
    ...mapState(["env"]),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    }
  }
};
</script>
<style scoped>
.header_dk {
  text-align: center;
  padding-top: 0px;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  top: 0;
}
.client_logo {
  max-width: 475px;
}
.ContainerImage {
  margin: 120px auto;
  margin-bottom: 157px;
}
</style>
